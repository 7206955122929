import {
	Box,
	Button,
	CircularProgress,
	CircularProgressProps,
	Unstable_Grid2 as Grid,
	Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useKioskRouter } from '../../utils/useKioskRouter';
import { StepType } from './steps';

export const UserInfoPageHeader = ({ curStep }: { curStep: StepType }) => {
	const { navigate홈으로 } = useKioskRouter();
	return (
		<Grid
			container
			sx={{ alignItems: 'center' }}>
			<Grid xs={2}>
				<Button
					onClick={navigate홈으로}
					variant='outlined'
					endIcon={
						<CircularProgressWithlabel
							curStep={curStep}
							timeout={15}
							onTimeoutCallback={navigate홈으로}
						/>
					}>
					HOME
				</Button>
			</Grid>
			<Grid xs={8}>
				<Typography
					variant='h5'
					textAlign={'center'}>
					{curStep === '이용약관' ? '이용 약관' : 'ㅤ'}
				</Typography>
			</Grid>
			<Grid xs={2}></Grid>
		</Grid>
	);
};

const CircularProgressWithlabel = ({
	onTimeoutCallback,
	timeout,
	onClick,
	curStep,
	...others
}: CircularProgressProps & {
	timeout: number;
	onTimeoutCallback: () => void;
	curStep: StepType;
}) => {
	const [seconds, setSeconds] = useState(timeout);

	useEffect(() => {
		const mouseEvent = (event: MouseEvent) => {
			setSeconds(timeout);
		};

		const keyDownEvent = (event: KeyboardEvent) => {
			setSeconds(timeout);
		};

		window.addEventListener('mousedown', mouseEvent);
		window.addEventListener('keydown', keyDownEvent);

		return () => {
			window.removeEventListener('mousedown', mouseEvent);
			window.removeEventListener('keydown', keyDownEvent);
		};
	}, []);

	useEffect(() => setSeconds(timeout), [curStep]);

	useEffect(() => {
		const interval = setInterval(() => setSeconds((prev) => prev - 1), 1000 * 1);

		return () => clearInterval(interval);
	}, []);

	useEffect(() => {
		if (seconds === 0) onTimeoutCallback();
	}, [seconds]);

	return (
		<Box
			sx={{ position: 'relative', display: 'inline-flex' }}
			onClick={onClick}>
			<CircularProgress
				{...others}
				variant='determinate'
				value={(100 * seconds) / timeout}
			/>
			<Box
				sx={{
					top: 0,
					left: 0,
					bottom: 0,
					right: 0,
					position: 'absolute',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}>
				<Typography
					variant='body2'
					component='div'
					color='primary'>
					{seconds}
				</Typography>
			</Box>
		</Box>
	);
};
