import { OnlineTicketType } from '@kinderlabs-pos/shared-data-type';
import { getUuidV4 } from '@kinderlabs-pos/shared-util';
import {
	AdminKioskInfoState,
	AdminProductInfoState,
	KioskElectronStoreState,
	OrderState,
	StoreInfoState,
} from '@kinderlabs-pos/state';
import { useConfirm } from '@kinderlabs-pos/ui-components';
import * as Sentry from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';

export const useKioskOnlineTicketFormik = ({
	onCompleteOrder,
}: {
	onCompleteOrder: (orderId: string) => void;
}) => {
	const onlineTicketMessage =
		KioskElectronStoreState.useKioskMainPageLocalSettings().data?.onlineTicketMessage;
	const [retrial, setRetrial] = useState(0);

	const storeInfo = useAtomValue(StoreInfoState.curStoreInfo);
	const productMap = AdminProductInfoState.useMapInStoreQuery({ storeId: storeInfo.id });
	const savedKioskInfo = StoreInfoState.useCurDeviceInfoForOrder();

	const { data: kioskInfo } = useQuery({
		...AdminKioskInfoState.keys.detail({ storeId: storeInfo.id, kioskId: savedKioskInfo.id }),
		keepPreviousData: true,
		refetchOnWindowFocus: true,
	});
	const createKioskOrderPayment = OrderState.actions.useCreateKioskOrderPayment({
		storeInfo,
		deviceId: kioskInfo?.id ?? -1,
	});

	const cartDispatch = OrderState.cart.useDispatcher();
	useEffect(() => {
		return cartDispatch({ type: 'CLEAR' });
	}, []);

	const customConfirm = useConfirm();

	const changeSubmitMessage = (submitMessage: string) =>
		formik.setValues((prev) => ({
			...prev,
			submitMessage,
		}));

	const formik = useFormik<{
		tickets: OnlineTicketType[];
		submitMessage: string;
	}>({
		initialValues: { tickets: [], submitMessage: '' },
		validationSchema: Yup.object({
			tickets: Yup.array().min(1),
		}),
		onSubmit: (values) => {
			customConfirm(onlineTicketMessage?.kor ?? '온라인권을 사용하시겠습니까?', () => {
				values.tickets.forEach((onlineTicketInfo) => {
					cartDispatch({
						type: 'ONLINE_TICKET_ONOFFMIX',
						subAction: {
							type: 'ADD',
							cartLineId: getUuidV4(),
							onlineTicketInfo,
							productInfoId: AdminProductInfoState.utils.getProductInfoIdFromMap({
								productId: onlineTicketInfo.externalTicketInfoId,
								productMap,
							})?.id,
						},
					});
				});

				setTimeout(() => {
					createKioskOrderPayment
						.mutateAsync({ paymentRequestData: undefined })
						.then(async (result) => {
							onCompleteOrder(result.order.id);
						})
						.catch((e) => {
							// ERROR 02
							Sentry.withScope((scope) => {
								// scope.setTransactionName('키오스크 온라인권 오류2');

								scope.setTags({
									Type: '결제',
									Where: 'KIOSK',
								});
								Sentry.captureMessage(
									`키오스크 온라인권 주문 실패 (Store ID : ${storeInfo.id}) // ` + JSON.stringify(e)
								);
							});

							changeSubmitMessage(
								retrial > 2
									? '발권 에러가 반복됩니다. 프론트로 방문해주세요.'
									: '발권 에러입니다. 잠시 후 다시 시도해주세요.'
							);
							setRetrial((prev) => prev + 1);
						});
				}, 200);
			});
		},
		validateOnMount: true,
	});

	return formik;
};
