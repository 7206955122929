import { CheckOutlined, LoadingOutlined, PrinterOutlined } from '@ant-design/icons';
import { AdminKioskInfoState, OrderState, StoreInfoState } from '@kinderlabs-pos/state';
import { Button, Stack, Typography, debounce } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useAtomValue, useSetAtom } from 'jotai';
import { Suspense, useEffect, useState } from 'react';
import { KioskOnlineTicketBarcodeReaderSection } from './KioskOnlineTicketBarcodeReaderSection';
import { KioskOnlineTicketResultDialog } from './KioskOnlineTicketResultDialog';
import { KioskOnlineTicketSection } from './KioskOnlineTicketSection';
import { useKioskOnlineTicketFormik } from './useKioskOnlineTicketFormik';
import { useKioskTranslation } from 'apps/kiosk-main/src/const/useKioskTranslation';
import { KioksUserInfoPage } from '../../KioskUserInfoMappingPage';

export const KioskOnlineTicketPage = () => {
	return (
		<Suspense>
			<KioskOnlineTicketPageInternal />
		</Suspense>
	);
};

export const KioskOnlineTicketPageInternal = () => {
	const { t } = useKioskTranslation();
	const guestMemberInfo = useAtomValue(KioksUserInfoPage.finalUserInfo);
	console.log(guestMemberInfo);
	const orderReducer = useSetAtom(OrderState.value);
	useEffect(() => {
		return () => {
			orderReducer({ type: 'CLEAR' });
		};
	}, []);
	// 분명히 쓸모없는 로직인데, Electron + KIOSK 로직에 뭔가 크게 이상한 점이있다.
	const cartDispatch = OrderState.cart.useDispatcher();
	useEffect(() => {
		return cartDispatch({ type: 'CLEAR' });
	}, []);

	const [resultOrderId, setResultOrderId] = useState<string | undefined>(undefined);

	const storeInfo = useAtomValue(StoreInfoState.curStoreInfo);
	const savedKioskInfo = StoreInfoState.useCurDeviceInfoForOrder();

	const { data: kioskInfo } = useQuery({
		...AdminKioskInfoState.keys.detail({ storeId: storeInfo.id, kioskId: savedKioskInfo.id }),
		keepPreviousData: true,
		refetchOnWindowFocus: true,
	});

	const createKioskOrderPayment = OrderState.actions.useCreateKioskOrderPayment({
		storeInfo,
		deviceId: kioskInfo?.id ?? -1,
		guestMemberInfo,
	});

	const formik = useKioskOnlineTicketFormik({
		onCompleteOrder: (orderId: string) => setResultOrderId(orderId),
	});

	const { values, isValid, handleSubmit } = formik;

	const handleApplyOnlineTickets = debounce(() => handleSubmit(), 100);

	return (
		<Stack
			overflow='auto'
			flex={1}>
			<Stack
				px={5}
				sx={(theme) => ({ bgcolor: theme.palette.primary.main })}
				minHeight={100}
				justifyContent={'center'}>
				<Typography
					variant={'h5'}
					color={'white'}>
					{t('onlineTicket.title')}
				</Typography>
			</Stack>
			<KioskOnlineTicketBarcodeReaderSection formik={formik} />
			<Stack
				component={'section'}
				px={3}>
				<Typography
					alignSelf={'flex-end'}
					textAlign={'right'}
					color={'text.secondary'}
					component='span'>
					{t('onlineTicket.info')}
				</Typography>
			</Stack>
			<KioskOnlineTicketSection formik={formik} />
			<Stack p={3}>
				<Typography color='error'>{values.submitMessage}</Typography>
			</Stack>
			<Stack p={3}>
				<Button
					style={{ borderWidth: 2 }}
					onClick={handleApplyOnlineTickets}
					fullWidth
					disabled={!isValid || createKioskOrderPayment.isLoading}
					variant='contained'
					startIcon={
						createKioskOrderPayment.isLoading ? (
							<LoadingOutlined style={{ fontSize: '120%' }} />
						) : isValid ? (
							<PrinterOutlined style={{ fontSize: '120%' }} />
						) : (
							<CheckOutlined style={{ fontSize: '120%' }} />
						)
					}>
					<Typography variant={'h6'}>
						{isValid ? t('onlineTicket.publish') : t('onlineTicket.pleaseAdd')}
					</Typography>
				</Button>
			</Stack>
			{resultOrderId && (
				<KioskOnlineTicketResultDialog
					orderId={resultOrderId}
					open
				/>
			)}
		</Stack>
	);
};
